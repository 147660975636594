import React from 'react'
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import Discover  from '../components/Discover';
import Features from '../components/Features';  
import Pricing from '../components/Pricing';
import Cta from '../components/Cta';
import Faqs from '../components/Faqs';
import Footer from '../components/Footer';

import '../assets/css/home.css'
const Home = () => {
  return (
    <>
     <Helmet>
        <title>Desklogic: Swift, Modern Office Suite for Home Inspectors</title>
        <meta name="description" content="Discover Desklogic: The ultimate home inspection software. Streamline office management and report writing with our innovative technology. Boost efficiency and accuracy in your home inspection business. Try Desklogic today!" />
      </Helmet>
     
      
      <Navbar />
      <Hero />
      <Banner />
      <Discover />
      <Features />
      <Pricing />
      <Cta />
      <Faqs />
      <Footer />
    </>
    
  )
}

export default Home
