import React from 'react'

const Cta = () => {
    return (
      <section className="cta text-white">
        <div className="container">
          <h2>Ready to Transform Your Home Inspection Process?</h2>
          <p className="lead">Experience the future of home inspections. Sign up now!</p>
          <a href="#" className="btn btn-primary btn-lg text-white">Start Your Free Trial</a>
        </div>
      </section>
    )
}

export default Cta