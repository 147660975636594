import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import Connect from './pages/Connect';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import ScrollToTop from './functions/ScrollToTop';
import ScrollToTopButton from './functions/ScrollToTopButton';
import './App.css';
// import Lunching from './components/Lunching';
import Lunching from './assets/images/lunching.svg';

function Modal({ onClose, show }) {
  return (
    <div className={`modal ${show ? 'show' : 'hide'}`}>
      <div className="modal-content">

      <div className="content">

      <span className="close" onClick={onClose}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"/></svg>
        </span>
      
        <div className="announcement">
    <h1>Exciting News!</h1>
   {/* <h2 className="subtitle">Desklogic is Coming Soon!</h2> */}
    <p>We are in development, with <span className="highlight">Phase 1: Office Management</span> launching soon, followed by <span className="highlight">Phase 2: Report Writing</span>.</p>
    <p>The report writer is still in the research phase, so sample reports are not yet available.</p>
    <p>Stay tuned for updates on the office portal release and demo availability via <span className="highlight">Facebook, email, and our website</span>.</p>
    

    <div className="announcement-footer">Thank you for your interest in Desklogic!</div>
  </div>

      </div>
    <div className="graphics d-flex align-items-center">
<img src={Lunching} alt="Lunching" />
</div>
      </div>
    </div>
  );
}

function App() {
  const [showModal, setShowModal] = useState(false);


    // useEffect(() => {
  //   const modalShown = localStorage.getItem('modalShown');
  //   if (!modalShown) {
  //     const timer = setTimeout(() => {
  //       setShowModal(true);
  //       localStorage.setItem('modalShown', 'true');
  //     }, 15000);
  //     return () => clearTimeout(timer);
  //   }
  // }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Router>
      <ScrollToTop />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/connect" element={<Connect />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <ScrollToTopButton />
        <Modal onClose={closeModal} show={showModal} />
      </div>
    </Router>
  );
}

export default App;