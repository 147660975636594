import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Cta from '../components/Cta';

const Terms = () => {
    return (
       <>
        <Helmet>
        <title>DeskLogic: Terms of Use | Home Inspection Software</title>
        <meta name="description" content="Read DeskLogic's Terms of Use for our home inspection software. Learn about our subscription-based SaaS application, payment terms, and user responsibilities. Streamline your home inspection business with DeskLogic." />
      </Helmet>
        <Navbar />
        <section className="hero pb-50">
            <div className="hero-inner">
            <div className="container">
                <h1 className="display-4">
                Terms of use
                </h1>
                <p className="mt-20">
                Last updated: August 01, 2024                </p>
            </div>
            </div>
        </section>
        <section className="page-content">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="feature-card content-terms">
                        <p>Welcome to DeskLogic. These Terms of Use ("Terms") govern your use of DeskLogic ("we," "us," "our") SaaS application for home inspection business management and reporting. By accessing or using our services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our services.</p>
      
      <h2>1. Acceptance of Terms</h2>
      <p>By using DeskLogic, you agree to comply with and be bound by these Terms, our Privacy Policy, and any additional terms applicable to certain programs in which you may elect to participate.</p>
      
      <h2>2. Description of Services</h2>
      <p>DeskLogic provides a subscription-based software as a service (SaaS) application designed to manage and report on home inspection businesses. Our services are offered on a monthly subscription basis, and include online payment processing through Stripe.</p>
      
      <h2>3. Eligibility</h2>
      <p>You must be at least 18 years old and capable of forming a binding contract to use DeskLogic. By using our services, you represent and warrant that you meet these requirements.</p>
      
      <h2>4. Account Registration</h2>
      <p>To access and use our services, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your password and agree not to disclose it to any third party. You are solely responsible for any activities or actions under your account, whether or not you have authorized such activities or actions.</p>
      
      <h2>5. Subscription and Payment</h2>
      <h3>5.1 Subscription Terms</h3>
      <p>DeskLogic is offered on a monthly subscription basis. By subscribing, you agree to pay the monthly subscription fee specified at the time of your subscription.</p>
      
      <h3>5.2 Payment Method</h3>
      <p>We use Stripe to process online payments. By providing your payment information, you authorize us to charge your payment method on a recurring monthly basis for the subscription fee. If your payment method fails, we may suspend or terminate your access to the services.</p>
      
      <h3>5.3 Cancellation</h3>
      <p>You may cancel your subscription at any time. If you cancel, you will continue to have access to the services until the end of your current billing period. We do not provide refunds or credits for any partial subscription periods.</p>
      
      <h2>6. Use of Services</h2>
      <h3>6.1 License</h3>
      <p>Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the services for your internal business purposes.</p>
      
      <h3>6.2 Prohibited Conduct</h3>
      <p>You agree not to:
        <ul>
          <li>Use the services for any illegal purpose or in violation of any local, state, national, or international law.</li>
          <li>Interfere with or disrupt the services or servers or networks connected to the services.</li>
          <li>Attempt to gain unauthorized access to any part of the services, other accounts, computer systems, or networks connected to the services.</li>
          <li>Use any robot, spider, scraper, or other automated means to access the services for any purpose without our express written permission.</li>
        </ul>
      </p>
      
      <h2>7. Intellectual Property</h2>
      <p>All content, trademarks, service marks, trade names, logos, and icons are proprietary to DeskLogic or its licensors. You are not permitted to use these without our prior written consent.</p>
      
      <h2>8. Termination</h2>
      <p>We may terminate or suspend your access to the services, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the services will immediately cease.</p>
      
      <h2>9. Disclaimer of Warranties</h2>
      <p>The services are provided "as is" and "as available" without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
      
      <h2>10. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law, in no event shall DeskLogic be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the services; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party; or (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the services, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.</p>
      
      <h2>11. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of the State of [Your State], without regard to its conflict of law principles.</p>
      
      <h2>12. Changes to Terms</h2>
      <p>We reserve the right to modify these Terms at any time. If we make changes to these Terms, we will provide notice of such changes, such as by sending an email notification, providing notice through the services, or updating the "Last Updated" date at the top of these Terms. Your continued use of the services will confirm your acceptance of the revised Terms.</p>
      
      <h2>13. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at clients@desklogic.com.</p>
    
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Cta />
        <Footer />
       </>
    );
};

export default Terms;