import React from 'react'
import { Helmet } from 'react-helmet';
import '../assets/css/components/hero.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Cta from '../components/Cta';

const Hero = () => {
    return (
    <>  
       <Helmet>
         <title>Contact Desklogic Support | Expert Assistance for Home Inspection Software</title>
         <meta name="description" content="Get in touch with Desklogic's dedicated support team for expert help with your home inspection software. Our friendly professionals offer live chat support and personalized assistance to optimize your workflow and maximize efficiency." />
       </Helmet>    <Navbar />
    <section className="hero">
        <div className="hero-inner">
          <div className="container">
            <h1 className="display-4">
              Contact our
              <span className="position-relative">
                {' '}
                help 

              </span>{' '}
              desk for assistance
            </h1>
            <p className="lead"> </p>
            <p>Reach Out to Our Support Team for Help and Guidance</p>
            

            <div className="banner-list mb-40">
              <ul>
                <li className="bdevs-el-subtitle">
                  <svg
                    width="800px"
                    height="800px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                    <path d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z"></path>
                  </svg>{' '}
                  Friendly Support Team
                </li>
                <li className="bdevs-el-subtitle">
                  <svg
                    width="800px"
                    height="800px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                    <path d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z"></path>
                  </svg>{' '}
                  Live chat support
                </li>
                <li className="bdevs-el-subtitle">
                  <svg
                    width="800px"
                    height="800px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                    <path d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z"></path>
                  </svg>{' '}
                  Prompt Email Response
                </li>
              </ul>
            </div>
            <a href="#" className="btn btn-primary btn-lg">
              Start Your Free Trial
            </a>
          </div>
        </div>
      </section>
      <section>
        {/* Add your new content here */}
      </section>
      <section className="mb-70 py-30 connect">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <span className="icon-map-pin"></span>
                  <h5 className="card-title">Our Address</h5>
                  <p className="card-text">263 Lakeview Dr. Farmington, MO 63640</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <span className="icon-mail"></span>
                  <h5 className="card-title">Email Us</h5>
                  <p className="card-text">
                    clients@desklogic.com</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <span className="icon-phone"></span>
                  <h5 className="card-title">Call Us</h5>
                  <p className="card-text">
                    +1 (618) 722-9189</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Cta/>
      <Footer />
      
    </>
    )
}

export default Hero