import React, { useState } from 'react';
import '../assets/css/components/faqs.css';

const Faqs = () => {
  const [faqs, setFaqs] = useState([
    {
      title: "What is this SaaS application for?",
      text: "This application is designed to help home inspectors manage their home inspection business and generate detailed reports. Currently it provides tools for scheduling, instant notifications and agreements, radon tracking, employee management, and detailed analytics. With a goal of 1 Jan 2025, the application will include a full report writer which will generate comprehensive reports for clients.",
      isActive: false
    },
    {
      title: "Is there a mobile version of the application?",
      text: "Yes, the application will be available on both iOS and Android platforms, allowing inspectors to conduct and manage inspections directly from their mobile devices once the report portion of the website releases.",
      isActive: false
    },
    {
      title: "How do I sign up for the application?",
      text: "To sign up, visit our website and click on the \"Sign Up\" button. Follow the prompts to create your account by entering your details and selecting a subscription plan.",
      isActive: false
    },
    {
      title: "What are the system requirements for using the application?",
      text: "The application is web-based and compatible with all modern browsers (Chrome, Firefox, Safari, Edge). For mobile use, it requires iOS 12.0 or later for Apple devices and Android 7.0 or later for Android devices.",
      isActive: false
    },
    {
      title: "Is there a trial version available?",
      text: "Yes, we offer a 30 days free trial with full access to all features. No credit card is required to sign up for the trial.",
      isActive: false
    },
    {
      title: "How do I schedule an inspection?",
      text: "After logging in, navigate to the \"Inspections\" section and click on \"Schedule Inspection\". Enter the details of the property and the inspection date and time, then save the appointment.",
      isActive: false
    },
    {
      title: "Can I customize the inspection report templates?",
      text: "Yes, the application will provide customizable report templates. You can add your logo, modify the layout, and include additional sections as needed.",
      isActive: false
    },
    {
      title: "Can multiple users access the same account?",
      text: "Yes, our application supports multi-user access and will allow for team reporting. You can add team members to your account and assign different roles and permissions to each user.",
      isActive: false
    },
    {
      title: "What should I do if I forget my password?",
      text: "Click on the \"Forgot Password\" link on the login page, enter your email address, and follow the instructions to reset your password.",
      isActive: false
    },
    {
      title: "How can I contact customer support?",
      text: "You can reach our customer support team via email at clients@desklogic.com, by calling 618-722-9189 or through the live chat feature on our website. Support is available 24/7.",
      isActive: false
    }
  ]);

  const toggleFaq = (index) => {
    setFaqs(faqs.map((faq, i) => (
      i === index ? { ...faq, isActive: !faq.isActive } : faq
    )));
  };

  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mb-50">
            <h1>Frequently Asked Questions</h1>
            <p className="lead">Answers to help you get started and succeed.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {faqs.slice(0, 5).map((faq, index) => (
              <div className={`faq ${faq.isActive ? 'active' : ''}`} key={index}>
                <h3 className="faq-title">{faq.title}</h3>
                <p className="faq-text">{faq.text}</p>
                <button className="faq-toggle" onClick={() => toggleFaq(index)}>
                  <span className="icon-chevron-down"></span>
                  <span className="icon-cross"></span>
                </button>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            {faqs.slice(5).map((faq, index) => (
              <div className={`faq ${faq.isActive ? 'active' : ''}`} key={index + 5}>
                <h3 className="faq-title">{faq.title}</h3>
                <p className="faq-text">{faq.text}</p>
                <button className="faq-toggle" onClick={() => toggleFaq(index + 5)}>
                  <span className="icon-chevron-down"></span>
                  <span className="icon-cross"></span>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
