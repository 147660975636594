import React from 'react';
import shapeImage from '../assets/images/shape.svg';
import logoSvg from '../assets/images/logo.svg';
import circleAnimSvg from '../assets/images/logo-text-circle.svg';
import '../assets/css/components/hero.css'

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-inner">
        <div className="container">
          <h1 className="display-4">Your Trusted <br /> Partner for <span className="position-relative">Seamless <img src={shapeImage} alt="" className="position-absolute word-shape" /></span> Home Inspections </h1>
          <p className="lead">Say goodbye to stressful home inspections and get the <br /> lightning-fast, modern software for efficient, accurate, and hassle-free home inspections.</p>
          
          <div className="banner-list mb-40">
            <ul>
              {['Next-gen tech features', '99.9% Server Uptime', 'Live Chat Support'].map((item, index) => (
                <li key={index} className="bdevs-el-subtitle">
                  <svg width="800px" height="800px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                    <path d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z"></path>
                  </svg>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <a href="#" className="btn btn-primary btn-lg">Start Your Free Trial</a>

          <div className="animated-logo animate-y">
            <div className="logo-anim">
              <img src={logoSvg} alt="Logo Animation" />
            </div>
            <img src={circleAnimSvg} alt="Circle Animation" className="circle-anim" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;