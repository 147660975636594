import React, { useState } from 'react';
import '../assets/css/components/faqs.css';

const Faqs = () => {
  const [faqs, setFaqs] = useState([
    {
      title: "Can I upgrade my plan later?",
      text: "Yes, you can upgrade your plan at any time to access more features and support for additional users.",
      isActive: false
    },
    {
      title: "Is there a free trial available?",
      text: "Yes, we offer a 30 days free trial with full access to all features. No credit card is required to sign up for the trial.",
      isActive: false
    },
    {
      title: "What payment methods do you accept?",
      text: "We accept all major credit cards and PayPal. Payments are securely processed through our encrypted payment gateway.",
      isActive: false
    },
    {
      title: "Can I cancel my subscription anytime?",
      text: "Yes, you can cancel your subscription at any time. There are no cancellation fees, and you will retain access to your plan features until the end of your billing cycle.",
      isActive: false
    },
    {
      title: "Are there any hidden fees?",
      text: "No, there are no hidden fees. All costs are transparently listed, and you only pay for what you choose. Note that all plans have the same features.",
      isActive: true
    }
  ]);

  const toggleFaq = (index) => {
    setFaqs(faqs.map((faq, i) => (
      i === index ? { ...faq, isActive: !faq.isActive } : faq
    )));
  };

  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mb-50">
            <h1>Frequently Asked Questions</h1>
            <p className="lead">Answers to help you get started and succeed.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {faqs.slice(0, 3).map((faq, index) => (
              <div className={`faq ${faq.isActive ? 'active' : ''}`} key={index}>
                <h3 className="faq-title">{faq.title}</h3>
                <p className="faq-text">{faq.text}</p>
                <button className="faq-toggle" onClick={() => toggleFaq(index)}>
                  <span className="icon-chevron-down"></span>
                  <span className="icon-cross"></span>
                </button>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            {faqs.slice(3).map((faq, index) => (
              <div className={`faq ${faq.isActive ? 'active' : ''}`} key={index + 3}>
                <h3 className="faq-title">{faq.title}</h3>
                <p className="faq-text">{faq.text}</p>
                <button className="faq-toggle" onClick={() => toggleFaq(index + 3)}>
                  <span className="icon-chevron-down"></span>
                  <span className="icon-cross"></span>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
