import React from 'react'
import dashboardImage from '../assets/images/dashbaord.webp'
import '../assets/css/components/banner.css'
const Banner = () => {
    return (
      <section>
        <div className="desklogic-preview container text-center mt-4">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <h1>
                Good. Better. <span className="brand">Desk Logic.</span> <br />
                Inspection Software Perfected
              </h1>
              <p>Trust Desk Logic software that meets your needs and exceeds expectations, setting new standards in inspection innovations.</p>
            </div>
            <div className="col-md-12">
            <img src={dashboardImage} alt="Dashboard preview" className="img-fluid preview" style={{ maxWidth: '80%', marginTop: '50px' }} />
          
           </div>
          </div>
        </div>
      </section>
    )
}

export default Banner