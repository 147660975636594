import React from 'react';
import '../assets/css/components/pricing.css'

const Pricing = () => {
    return (
      <section className="appie-pricing-2-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-70">
              <div className="appie-section-title text-center">
                <h2 className="appie-title">Simple Pricing For Everyone</h2>
                <p>Affordable plans with 30 days free trial and unlimited inspections.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div className="pricing-one__single pricing-one__single_2 wow animated fadeInLeft animated" style={{visibility: 'visible', animationName: 'fadeInLeft'}}>
                    <div className="pricig-heading">
                      <h6>Solo Inspector</h6>
                      <div className="price-range"><sup>$</sup> <span>69</span><p>/month</p></div>
                      <p>30 Days Free Trial. No Credit Card Required.</p>
                    </div>
                    <div className="pricig-body">
                      <ul>
                        <li><i className="fal fa-check"></i> Single Home Inspector</li>
                        <li><i className="fal fa-check"></i> Unlimited Inspections</li>
                        <li><i className="fal fa-check"></i> 24/7 Support</li>
                      </ul>
                      <div className="pricing-btn mt-35">
                        <a className="btn btn-primary btn-lg" href="#!">Choose plan</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="pricing-one__single pricing-one__single_2 active wow animated fadeInUp animated" style={{visibility: 'visible', animationName: 'fadeInUp'}}>
                    <div className="pricig-heading">
                      <h6>Team Inspect</h6>
                      <div className="price-range"><sup>$</sup> <span>99</span><p>/month</p></div>
                      <p>30 Days Free Trial. No Credit Card Required.</p>
                    </div>
                    <div className="pricig-body">
                      <ul>
                        <li><i className="fal fa-check"></i> Up to three employees</li>
                        <li><i className="fal fa-check"></i> Unlimited Inspections</li>
                        <li><i className="fal fa-check"></i> 24/7 Support</li>
                      </ul>
                      <div className="pricing-btn mt-35">
                        <a className="btn btn-primary btn-lg" href="#!">Choose plan</a>
                      </div>
                      <div className="pricing-rebon">
                        <span>Most Popular</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="pricing-one__single pricing-one__single_2 item-2 wow animated fadeInRight animated" style={{visibility: 'visible', animationName: 'fadeInRight'}}>
                    <div className="pricig-heading">
                      <h6>Unlimited Access</h6>
                      <div className="price-range"><sup>$</sup> <span>199</span><p>/month</p></div>
                      <p>30 Days Free Trial. No Credit Card Required.</p>
                    </div>
                    <div className="pricig-body">
                      <ul>
                        <li><i className="fal fa-check"></i> Unlimited Employees</li>
                        <li><i className="fal fa-check"></i> Unlimited Inspections</li>
                        <li><i className="fal fa-check"></i> 24/7 Support</li>
                      </ul>
                      <div className="pricing-btn mt-35">
                        <a className="btn btn-primary btn-lg" href="#!">Choose plan</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Pricing;