
import React from 'react'
import { Link } from 'react-router-dom'

import mobileMock from '../assets/images/mobile.png';


const Features = () => {
  return (
    <section className="features pt-0">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-6 pr-0 pr-md-5">
            <h2>Powerful Features for Home Inspectors</h2>
            <p className="lead">Desk Logic offers a comprehensive suite of tools designed to streamline your home inspection process:</p>
            <ul className="features-list">
              <li>Customizable inspection templates</li>
              <li>Instant report generation</li>
              <li>Mobile-friendly interface</li>
              <li>Client management system</li>
              <li>Integrated scheduling and invoicing</li>
              <li>Secure cloud storage for all your reports</li>
              <li>One click to export all your data.</li>
            </ul>
            <Link  to="/features" className="btn btn-primary btn-lg mt-15">Discover Features</Link>
          </div>
          <div className="col-md-6">
            <figure className="mobile-mock">
              <img src={mobileMock} alt="DESKLOGIC Features" className="img-fluid rounded" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features