import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Cta from '../components/Cta';

const Privacy = () => {
    return (
       <>
        <Helmet>
        <title>Privacy Policy | Desklogic: Secure Home Inspection Software</title>
        <meta name="description" content="Learn about Desklogic's commitment to your privacy. Our secure home inspection software protects your data while streamlining your business. Read our detailed privacy policy to understand how we safeguard your information." />
      </Helmet>
        <Navbar />
        <section className="hero pb-50">
            <div className="hero-inner">
            <div className="container">
                <h1 className="display-4">
                Our Privacy Policy
                </h1>
                <p className="lead"></p>
                <p>
                Last updated: August 01, 2024                </p>
            </div>
            </div>
        </section>
        <section className="page-content">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="feature-card">
                            <h2>Privacy Policy for Desklogic</h2>
                            <p>At Desklogic, located at 263 Lakeview Dr. Farmington, MO 63640, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our home inspector business management SaaS-based software.</p>

                                                    <h4>1. Information We Collect</h4>
                                                    <p>At Desklogic, we may collect various types of information to provide you with the best possible service and experience. This information includes, but is not limited to:</p>
                                                    <ul>
                                                        <li>Personal information: This encompasses details that can identify you as an individual, such as your full name, email address, phone number, and potentially other contact information.</li>
                                                        <li>Business information: For our corporate clients, we may collect data related to your company, including the official company name, physical address, and other relevant business details.</li>
                                                        <li>Financial information: To process transactions and provide our services, we may collect payment-related information. This could include credit card details, bank account information, or other payment method specifics.</li>
                                                        <li>Usage data: We gather information about how you interact with our software and services. This may include data on features you use frequently, time spent on various sections, and other behavioral patterns that help us improve our offerings.</li>
                                                    </ul>

                                                    <h4>2. How We Use Your Information</h4>
                                                    <p>The information we collect serves several important purposes. We utilize this data to:</p>
                                                    <ul>
                                                        <li>Provide and improve our services: Your information helps us tailor our offerings to your needs and continuously enhance the quality and functionality of our products.</li>
                                                        <li>Process payments: Financial information is used solely for the purpose of completing transactions related to our services, ensuring a smooth and secure payment process.</li>
                                                        <li>Communicate with you: We use your contact information to send important updates about our services, respond to your inquiries, and provide customer support.</li>
                                                        <li>Comply with legal obligations: In some cases, we may be required by law to collect, use, or retain certain information. We always strive to adhere to all applicable legal requirements.</li>
                                                    </ul>

                                                    <h4>3. Data Security</h4>
                                                    <p>Protecting your data is of utmost importance to us. We employ a comprehensive set of industry-standard security measures to safeguard your information. These measures are designed to prevent unauthorized access, maintain data accuracy, and ensure the appropriate use of information. Our security protocols include encryption, firewalls, secure socket layer (SSL) technology, and regular security audits. While no method of data transmission over the Internet or electronic storage is 100% secure, we continuously strive to improve our security measures to protect your data from unauthorized access, disclosure, alteration, and destruction.</p>

                                                    <h4>4. Data Sharing</h4>
                                                    <p>We want to assure you that we do not sell your personal information under any circumstances. Your privacy is paramount to us. However, in order to provide our services effectively, we may share your data with carefully selected third-party service providers. These providers assist us in various aspects of our business operations, such as hosting services, payment processing, and customer support tools. We only share information to the extent necessary for these providers to perform their specific functions, and we ensure that they are bound by strict confidentiality agreements and data protection obligations.</p>

                                                    <h4>5. Your Rights</h4>
                                                    <p>We believe in empowering our users with control over their personal information. As such, you have several important rights regarding your data:</p>
                                                    <ul>
                                                        <li>Right to Access: You can request a copy of the personal information we hold about you.</li>
                                                        <li>Right to Rectification: If you believe any of your information is inaccurate or incomplete, you have the right to ask us to correct it.</li>
                                                        <li>Right to Erasure: Also known as the 'right to be forgotten', you can ask us to delete your personal information in certain circumstances.</li>
                                                        <li>Right to Data Portability: You may request that we transfer your data to another service provider in a machine-readable format.</li>
                                                        <li>Right to Withdraw Consent: If we are processing your data based on your consent, you have the right to withdraw that consent at any time.</li>
                                                    </ul>
                                                    <p>To exercise any of these rights, please contact us using the information provided at the end of this policy.</p>

                                                    <h4>6. Changes to This Policy</h4>
                                                    <p>As our services evolve and privacy regulations change, we may need to update this Privacy Policy from time to time. When we make significant changes, we will notify you by posting a prominent notice on our website or by sending you a direct notification. We encourage you to review this policy periodically to stay informed about how we are protecting your information. The date of the most recent revision will always be clearly indicated at the top of the policy.</p>

                                                    <h4>7. Contact Us</h4>
                                                    <p>We value transparency and are always here to address any questions or concerns you may have about our Privacy Policy or data practices. If you need further information, wish to exercise your data rights, or have any other inquiries, please don't hesitate to contact us at:</p>
                                                    <p>Desklogic<br />263 Lakeview Dr.<br />Farmington, MO 63640<br />Email: privacy@desklogic.com<br />Phone: (555) 123-4567</p>
                                                    <p>Our dedicated privacy team is committed to responding to your queries promptly and thoroughly, typically within 5 business days.</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Cta />
        <Footer />
       </>
    );
};

export default Privacy;