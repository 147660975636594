import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Cta from '../components/Cta';
import '../assets/css/components/hero.css';
import '../assets/css/components/banner.css';
import '../assets/css/components/features.css';
import deviceMock from '../assets/images/combine-mock.png';
import shapeMock from '../assets/images/shape.svg';
const Features = () => {
    return (
      <>
       <Helmet>
        <title>Desklogic Features: Advanced Tools for Home Inspection Management</title>
        <meta name="description" content="Explore Desklogic's exceptional features for home inspection management. Advanced tools, drag & drop calendar, and future-proof software to streamline your workflow. Start your free trial today!" />     
       </Helmet>
      <Navbar />
        <section className="hero">
          <div className="hero-inner">
            <div className="container">
              <h1 className="display-4">Exceptional Features for Enhanced Home
                <span className="position-relative">  Inspection <img src={shapeMock} alt="" className="position-absolute word-shape" /></span> Management</h1>              <p className="lead">Empowering Home Inspectors with Cutting-Edge Tools</p>
            
              <div className="banner-list mb-40">
                <ul>
                  <li className="bdevs-el-subtitle">
                    <svg width="800px" height="800px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                      <path d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z"></path>
                    </svg> Advanced Inspection Tools
                  </li>
                  <li className="bdevs-el-subtitle">
                    <svg width="800px" height="800px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                      <path d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z"></path>
                    </svg>Drag & Drop Calendar
                  </li>
                  <li className="bdevs-el-subtitle">
                    <svg width="800px" height="800px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                      <path d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z"></path>
                    </svg> Future-Proof Software
                  </li>
                </ul>
              </div>
              <a href="#" className="btn btn-primary btn-lg">Start Your Free Trial</a>                    
            </div>
          </div>
        </section>
        <div className="desklogic-preview container text-center mt-4">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <h1>All-Inclusive Access</h1>
              <p>One subscription grants you complete access to all features Desk Logic offers.</p>
            </div>
            <div className="col-md-10 mt-60 mb-100">
              <img src={deviceMock} alt="" className="img-fluid preview" />            </div>
          </div>
        </div>

        <section className="features-section mt-100 pt-50 mb-100">
          <div className="container">
            <div className="row justify-content-center mb-50">
              <div className="col-md-9 col-lg-9">
                <div className="section-heading text-center mb-50">
                  <h2>Desk Logic's Key Features</h2>
                  <p>Desk Logic is dedicated to providing top-notch software for home inspectors. Our tools help improve efficiency, accuracy, and professionalism.</p>
                </div>
              </div>
            </div>
          
            <div className="row">
              {[
                { title: "Efficient Scheduling", text: "Seamlessly schedule inspections with an intuitive calendar that syncs across all devices. Avoid double bookings and ensure every inspection is on time, enhancing your workflow and client satisfaction." },
                { title: "Automated Reminders", text: "Reduce no-shows and late arrivals with automated reminders for both inspectors, clients and agents. Ensure everyone is on the same page, improving punctuality and overall efficiency." },
                { title: "Customizable Templates", text: "Create detailed and professional inspection reports with customizable templates. Include photos, notes, and ratings to provide clients with clear, comprehensive information every time." },
                { title: "Instant Report Generation", text: "Generate reports on-site and share them with clients instantly. Save time with auto-fill features and quick data entry options, streamlining your inspection process." },
                { title: "Email Automation", text: "Boost efficiency with automated emails for inspectors, clients, and agents, keeping everyone informed and reducing manual reminders." },
                { title: "Responsive User Interface", text: "Deliver a consistent and user-friendly experience across all devices with a fully responsive UI, adapting to any screen size for optimal usability." },

                { title: "Easy Revenue Management", text: "Utilize built-in accounts to manage billing and invoicing. Ensure accurate financial tracking and simplify your bookkeeping directly within the platform." },
                { title: "iOS and Android Apps", text: "Dedicated mobile apps for iOS and Android provide full functionality on your smartphone or tablet. Conduct inspections, generate reports, and manage schedules from anywhere." },
                { title: "Encrypted Data Storage", text: "Ensure all your data is secure with state-of-the-art encryption. Protect client information and inspection details from unauthorized access, maintaining confidentiality." },
                { title: "Regular Backups", text: "Automated backups to safeguard your data against loss. Ensure you can always recover important information, providing peace of mind and data security." },
                { title: "Detailed Client Profiles", text: "Maintain comprehensive client profiles with contact information, inspection history, and notes. Easily track interactions and provide personalized service to each client." },
                { title: "Data-Driven Insights", text: "Harness the power of data to drive decisions with actionable insights, helping you make informed choices and optimize performance." },
                { title: "Multi-User Support", text: "Allow multiple inspectors to access and update information simultaneously. Facilitate collaboration and ensure everyone has the latest data, improving team productivity." },
                { title: "Role-Based Access", text: "Assign roles and permissions to team members, ensuring everyone has access to the tools and information they need without compromising security, enhancing organizational control." },
                { title: "Live Syncing", text: "Keep all your devices and team members in sync with real-time updates. Ensure everyone is working with the most current information, preventing any miscommunications." },
                { title: "Notification System", text: "Receive instant notifications for important events, such as new appointments, client messages, and report submissions. Stay informed and responsive at all times." },
                { title: "Flexible Payment Gateway", text: "Offer clients multiple payment options with a secure and flexible payment gateway, making transactions seamless and boosting customer satisfaction." },

                { title: "Flexible Plans", text: "Explore our dynamic plans and scale smoothly as your business progresses. Adapt your capacity effortlessly to fit your evolving needs." },
                { title: "Real-Time Collaborations", text: "Enhance teamwork with real-time collaboration features, allowing users to work together seamlessly and make instant updates." },
                { title: "24/7 Customer Service", text: "Get assistance whenever you need it with our round-the-clock customer support. Ensure any issues are resolved quickly, minimizing downtime and maintaining productivity." },
                { title: "Comprehensive Tutorials", text: "Access a library of tutorials and guides to help you make the most of DESKLOGIC's features. Gain knowledge and confidence in using the software to its full potential." }
              ].map((feature, index) => (
                <div key={index} className="col-md-4 col-lg-4">
                  <div className="feature-card">
                    <div className="feature-card-body">
                      <h3 className="feature-card-title">{feature.title}</h3>
                      <p className="feature-card-text">{feature.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <Cta />
        <Footer />
      </>
    );
};

export default Features;