import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Cta from '../components/Cta';
import Pricing from '../components/Pricing';
import Pricingfaqs from '../components/Pricingfaqs';
import shapeMock from '../assets/images/shape.svg';

const PricingHero = () => {
    return (
       <>
       <Helmet>
        <title>Desklogic Pricing: Affordable Plans for Home Inspection Professionals</title>
        <meta name="description" content="Choose the perfect pricing plan for your home inspection business with Desklogic. Flexible options, advanced features, and scalable solutions to boost your efficiency and growth. Compare plans and start your free trial today!" />       </Helmet>
        <Navbar />
        <section className="hero">
            <div className="hero-inner">
            <div className="container">
                <h1 className="display-4">
                Choose the Perfect Plan for Your Home{' '}
                <span className="position-relative">
                   Inspection
                    <img src={shapeMock} alt="" className="position-absolute word-shape" />
                </span>{' '}
                Business.
                </h1>
                <p className="lead"></p>
                <p>
                Explore our flexible pricing options designed to scale with your
                business and provide the best value.
                </p>

                <div className="banner-list mb-40">
                <ul>
                    <li className="bdevs-el-subtitle">
                    <svg
                        width="800px"
                        height="800px"
                        viewBox="0 0 16 16"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                        <path d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z"></path>
                    </svg>{' '}
                    Advanced Inspection Tools
                    </li>
                    <li className="bdevs-el-subtitle">
                    <svg
                        width="800px"
                        height="800px"
                        viewBox="0 0 16 16"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                        <path d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z"></path>
                    </svg>
                    Drag & Drop Calendar
                    </li>
                    <li className="bdevs-el-subtitle">
                    <svg
                        width="800px"
                        height="800px"
                        viewBox="0 0 16 16"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                        <path d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z"></path>
                    </svg>{' '}
                    Future-Proof Software
                    </li>
                </ul>
                </div>
                <a href="#" className="btn btn-primary btn-lg">
                Start Your Free Trial
                </a>
            </div>
            </div>
        </section>
        <Pricing />
        <Pricingfaqs />
        <Cta />
        <Footer />
       </>
    );
};

export default PricingHero;